import { ClockSupplyLine } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

import MarkButtonMenu from '@features/Supply/Components/Marking/MarkButtonMenu/MarkButtonMenuContainer';
import { useMarkingAccounts } from '@features/Supply/Hooks/useMarkingAccounts';
import { useMarkingsContext } from '@store/Providers/MarkingsProvider';

export interface IProps {
    readonly supplyLine: ClockSupplyLine;
    readonly size?: MarkSize;
}

export enum MarkSize {
    Medium = 'Medium',
    Small = 'Small',
}

export default function MarkingContainer({ supplyLine, size }: IProps) {
    const { data: markingAccounts } = useMarkingAccounts();
    const {
        state: { markings },
    } = useMarkingsContext();
    const markingDetail = markings.find(m => m.clockSupplyLineId === supplyLine.id);

    return (
        <MarkButtonMenu
            size={size}
            supplyLineId={supplyLine.id ?? ''}
            digitalAuctionSupplyType={supplyLine.digitalAuctionSupplyType}
            quantity={markingDetail?.amount}
            comment={markingDetail?.comment}
            users={markingAccounts?.accounts ?? []}
        />
    );
}
