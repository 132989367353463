import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import type { BarcodeCaptureListener } from 'scandit-web-datacapture-barcode';

import ScanResult from '@features/Scan/Components/ScannerComponent/Components/ScanResult/ScanResult';
import { useNavigatorOnLine } from '@root/Hooks/useNavigatorOnLine';
import { namespaces } from '@root/i18n';
import NoConnectionIcon from '@resources/Icons/NoConnectionIcon';
import { createOnScan, onMount, useSDK } from '@store/Providers/SdkProvider';
import { useStyles } from './ScannerComponentStyles';

export default function ScannerComponent(): JSX.Element {
    const host = useRef<HTMLDivElement | null>(null);
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.general);
    const [searchParams] = useSearchParams();
    const { loaded, sdk } = useSDK();
    const [barcode, setBarcode] = useState<string | null>(null);
    const currentBarcode = searchParams.get('barcode') ? searchParams.get('barcode') : barcode;
    const [scannerEnabled, setScannerEnabled] = useState(!currentBarcode);
    const isOnline = useNavigatorOnLine();

    function onDidScan(data: string) {
        setBarcode(data);
        setScannerEnabled(false);
    }

    const onScan = useMemo<BarcodeCaptureListener>(() => createOnScan(sdk, onDidScan), [sdk]);

    useEffect(() => {
        if (scannerEnabled) {
            onMount(loaded, host, sdk, onScan);
        }

        return () => {
            sdk.removeBarcodeCaptureListener(onScan);
            sdk.enableCamera(false);
            sdk.detachFromElement();
        };
    }, [loaded, sdk, onScan, barcode, scannerEnabled]);

    return (
        <div className={classes.container}>
            {!isOnline && (
                <div className={classes.networkContainer} data-testid='networkContainer'>
                    <NoConnectionIcon />
                    <span className={classes.offlineText}>{t('scan.offline')}</span>
                </div>
            )}
            {currentBarcode ? (
                <Paper data-testid='scannerHeader' className={`${classes.paper} ${classes.scannerHeader}`}>
                    <ScanResult barcode={currentBarcode?.slice(0, 12) ?? ''} />
                </Paper>
            ) : (
                <Paper data-testid='scannerHeader' className={`${classes.paper} ${classes.header}`}>
                    <Typography className={classes.title}>{t('scan.barcodeScanner')}</Typography>
                    {t('scan.instructions')}
                </Paper>
            )}
            <div className={`${classes.scannerContainer} ${currentBarcode ? classes.smallScannerContainer : ''}`}>
                <div className={`${classes.scanner} ${currentBarcode ? classes.smallScanner : ''}`} ref={host}>
                    {!scannerEnabled && (
                        <Paper className={classes.enableScannerContainer}>
                            <div className={classes.backdrop}>
                                <Button
                                    variant='contained'
                                    size='medium'
                                    color='primary'
                                    onClick={() => setScannerEnabled(true)}
                                >
                                    {t('scan.startScanner')}
                                </Button>
                            </div>
                        </Paper>
                    )}
                </div>
            </div>
        </div>
    );
}
