import { MarkingUsersActions } from '@store/Actions/markingUsersActions';

export interface MarkingUsersState {
    markings: { [clockSupplyLineId: string]: string[] };
}

export const initialState: MarkingUsersState = {
    markings: {},
};

export function markingUsersReducer(state = initialState, action: MarkingUsersActions): MarkingUsersState {
    switch (action.type) {
        case 'ADD_MARKING_USERS': {
            const usersInState = state.markings[action.clockSupplyLineId] ?? [];
            const usersToAdd = action.users.filter(user => !state.markings[action.clockSupplyLineId]?.includes(user));
            return {
                ...state,
                markings: {
                    ...state.markings,
                    [action.clockSupplyLineId]: [...usersInState, ...usersToAdd],
                },
            };
        }
        case 'ADD_MARKING_USER': {
            const usersInState = state.markings[action.clockSupplyLineId] ?? [];
            return {
                ...state,
                markings: {
                    ...state.markings,
                    [action.clockSupplyLineId]: [...usersInState, action.user],
                },
            };
        }
        case 'ADD_MARKING_USERS_FOR_SUPPLY_LINES': {
            const newMarkings = action.clockSupplyLineIds.reduce(
                (acc, cur) => ({ ...acc, [cur]: [...(acc[cur] ?? []), ...action.users] }),
                { ...state.markings },
            );

            return {
                ...state,
                markings: newMarkings,
            };
        }
        case 'DELETE_MARKING_USER': {
            const usersInState = state.markings[action.clockSupplyLineId] ?? [];
            return {
                ...state,
                markings: {
                    ...state.markings,
                    [action.clockSupplyLineId]: usersInState.filter(user => user !== action.user),
                },
            };
        }
        case 'DELETE_MARKING_USERS': {
            const usersInState = state.markings[action.clockSupplyLineId] ?? [];
            return {
                ...state,
                markings: {
                    ...state.markings,
                    [action.clockSupplyLineId]: usersInState.filter(user => !action.users.includes(user)),
                },
            };
        }
        default:
            return state;
    }
}
