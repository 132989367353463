import { useMutation, useQueryClient } from '@tanstack/react-query';

import AuctionPreparationClient from '@api/AuctionPreparationClient';

export function useSaveUserGroup() {
    const queryClient = useQueryClient();

    const client = AuctionPreparationClient.instance.getApiClient();

    const saveUserGroup = useMutation(async (userGroup: string[]) => client.saveUserGroup({ users: userGroup }), {
        onSuccess: () => queryClient.resetQueries({ queryKey: ['userGroup'] }),
    });

    return { saveUserGroup };
}
