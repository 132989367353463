import { useEffect, useState } from 'react';
import { DigitalAuctionSupplyType } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useLocation } from 'react-router-dom';

import { MarkSize } from '@features/Supply/Components/Marking/MarkingContainer/MarkingContainer';
import { ISupplyLineMarkingCommand } from '@features/Supply/Contracts/ISupplyLineMarkingCommand';
import { useMarking } from '@features/Supply/Hooks/useMarking';
import { useSupplyLineMarkings } from '@features/Supply/Hooks/useSupplyLineMarkings';
import { useUserGroup } from '@features/Supply/Hooks/useUserGroup';
import { addMarkingUsers, deleteMarkingUsers } from '@store/Actions/markingUsersActions';
import { useMarkingUsersContext } from '@store/Providers/MarkingUsersProvider';
import { useMarkingsContext } from '@store/Providers/MarkingsProvider';
import { useUserSettings } from '@store/Providers/UserSettingsProvider';
import MarkButtonMenu from './Components/MarkButtonMenu/MarkButtonMenu';

interface MarkButtonProps {
    readonly size?: MarkSize;
    readonly supplyLineId: string;
    readonly digitalAuctionSupplyType: DigitalAuctionSupplyType;
    readonly users: string[];
    readonly comment?: string;
    readonly quantity?: number;
}

export default function MarkButtonMenuContainer(props: MarkButtonProps) {
    const { size, supplyLineId, digitalAuctionSupplyType, comment, quantity, users } = props;

    const { createMarking, deleteMarking } = useMarking();
    const { data: markings } = useSupplyLineMarkings(supplyLineId);

    const {
        state: { markings: markingsInContext },
    } = useMarkingsContext();
    const { dispatch, state } = useMarkingUsersContext();

    const [supplyLineMarking, setSupplyLineMarking] = useState<ISupplyLineMarkingCommand | undefined>();

    const markingDetail = markingsInContext.find(m => m.clockSupplyLineId === supplyLineId);

    const { data: markingGroup } = useUserGroup();
    const markingGroupUsers = markingGroup?.users ?? [];

    const { userSettings } = useUserSettings();
    const location = useLocation();
    const usersWithMarking = state.markings[supplyLineId];

    const allUsersInGroupMarked = markingGroupUsers.every(user =>
        usersWithMarking?.some(userName => userName === user),
    );

    function handleGroupMarking(marked: boolean) {
        if (marked) {
            dispatch(addMarkingUsers(supplyLineId, markingGroupUsers));
        } else {
            dispatch(deleteMarkingUsers(supplyLineId, markingGroupUsers));
        }

        markingGroupUsers.forEach(user => {
            handleRowMarking(marked, user);
        });
    }

    function handleRowMarking(marked: boolean, username?: string) {
        if (!username) {
            setSupplyLineMarking(prevValue => ({
                id: prevValue?.id ?? '',
                clockSupplyLineId: supplyLineId ?? '',
                comment: '',
                amount: 0,
                userName: '',
                creationUserName: '',
            }));
        }

        if (marked) {
            createMarking.mutate({
                supplyLineMarkingCommand: {
                    id: supplyLineMarking?.id,
                    clockSupplyLineId: supplyLineId ?? '',
                },
                username,
            });
        } else {
            deleteMarking.mutate({
                clockSupplyLineId: supplyLineId ?? '',
                supplyLineMarkingCommand: !username ? (supplyLineMarking as ISupplyLineMarkingCommand) : undefined,
                username,
            });
        }
    }

    useEffect(() => {
        if (markings && markings.length > 0) {
            dispatch(
                addMarkingUsers(
                    supplyLineId,
                    markings.map(marking => marking.userName),
                ),
            );
        }

        if (markingDetail) {
            setSupplyLineMarking(markingDetail);
        } else {
            setSupplyLineMarking({
                id: '',
                clockSupplyLineId: '',
                comment: '',
                amount: 0,
                userName: '',
                creationUserName: '',
            });
        }
    }, [markings]);

    const markingGroupSize = markingGroup?.users?.length ?? 0;
    const markForGroup = userSettings.markForGroup && location.pathname.includes('scan') && markingGroupSize > 0;

    return (
        <MarkButtonMenu
            size={size}
            marked={markForGroup ? allUsersInGroupMarked : !!markingDetail}
            markForGroup={markForGroup}
            onMark={handleRowMarking}
            onGroupMark={handleGroupMarking}
            supplyLineId={supplyLineId}
            digitalAuctionSupplyType={digitalAuctionSupplyType}
            users={users}
            comment={comment}
            quantity={quantity}
        />
    );
}
