import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1),
        margin: theme.spacing(2, 0),
        '.MuiChip-root.Mui-disabled': {
            opacity: 1,
        },
    },
}));
