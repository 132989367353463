import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    container: {
        margin: theme.spacing(0, -2),
        width: '100vw',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    paper: {
        borderRadius: '0',
        padding: theme.spacing(2),
        boxShadow: '0px 4px 10px 0px #0000001A',
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    title: {
        color: '#000000',
        fontSize: '20px',
        fontWeight: 800,
        lineHeight: '32px',
        marginBottom: theme.spacing(1),
    },
    networkContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        backgroundColor: theme.palette.error.light,
        padding: theme.spacing(3),
        gap: theme.spacing(1),
    },
    offlineText: {
        marginRight: theme.spacing(2),
    },
    scannerContainer: {
        padding: theme.spacing(2),
    },
    scanner: {
        borderRadius: '10px',
        overflow: 'hidden',
    },
    smallScannerContainer: {
        backgroundColor: theme.palette.primary.main,
    },
    smallScanner: {
        height: '96px',
    },
    scannerHeader: {
        flexGrow: 1,
        overflow: 'auto',
    },
    enableScannerContainer: {
        height: '100%',
    },
    backdrop: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
}));
