import { PropsWithChildren, useEffect } from 'react';
import { SnackbarManager } from '@floriday/floriday-ui';
import {
    ClockSupplySearchCommand,
    SortDirection,
    SortField,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

import IQueueMarking from '@contracts/IQueueMarking';
import { useMarking } from '@features/Supply/Hooks/useMarking';
import { useSupplyLineIds } from '@features/Supply/Hooks/useSupplyLineIds';
import { useUserGroup } from '@features/Supply/Hooks/useUserGroup';
import { useNavigatorOnLine } from '@root/Hooks/useNavigatorOnLine';
import { resetQueue } from '@store/Actions/markingQueueActions';
import { useMarkingQueueContext } from '@store/Providers/MarkingQueueProvider';
import { useUserSettings } from '@store/Providers/UserSettingsProvider';
import { getDefaultAuctionDate } from '@utils/DateUtils';
import { getSnackbarTranslations } from '@utils/TranslationsUtils';

export default function OfflineContainer(props: Readonly<PropsWithChildren>) {
    const { children } = props;
    const isOnline = useNavigatorOnLine();
    const {
        state: { queue },
        dispatch,
    } = useMarkingQueueContext();
    const { createMarkings } = useMarking();
    const { userSettings } = useUserSettings();

    const searchCommand: ClockSupplySearchCommand = {
        query: '',
        skip: 0,
        take: 100,
        sorting: {
            field: SortField.AuctioningSequence,
            direction: SortDirection.Ascending,
        },
        hasMarking: false,
        hasPresale: false,
        searchFilterItems: [],
        searchRangeFilterItems: [],
        auctionDate: getDefaultAuctionDate(),
        returnAggregations: false,
    };

    const { isLoading, data } = useSupplyLineIds(
        isOnline && queue.length > 0,
        queue.map(marking => marking.barcode),
        searchCommand,
    );

    const { data: markingGroup } = useUserGroup({ enabled: isOnline });

    const markingGroupSize = markingGroup?.users?.length ?? 0;
    const markForGroup = userSettings.markForGroup && markingGroupSize > 0;

    function getSupplyLines(marking: IQueueMarking) {
        return data[marking.barcode]?.filter((_, resultIndex) => marking.index.includes(resultIndex)) ?? [];
    }

    useEffect(() => {
        if (isOnline && queue.length > 0 && !isLoading) {
            const supplyLinesToMark = queue.flatMap(getSupplyLines);

            if (supplyLinesToMark.length > 0) {
                if (markForGroup) {
                    markingGroup?.users.map(user =>
                        createMarkings.mutate({ supplyLines: supplyLinesToMark, username: user }),
                    );
                } else {
                    createMarkings.mutate({ supplyLines: supplyLinesToMark });
                }

                SnackbarManager.show(getSnackbarTranslations('offlinemarkingsaved', supplyLinesToMark.length));
            }

            dispatch(resetQueue());
        }
    }, [isOnline, queue, isLoading]);

    return <>{children}</>;
}
