import { IConfig } from './config';

function getConfiguration(): IConfig {
    return {
        apiUrl: import.meta.env.VITE_API_URL ?? '',
        appsPortalUrl: import.meta.env.VITE_APPS_PORTAL_API_URL ?? '',
        customerPortalUrl: import.meta.env.VITE_CUSTOMER_PORTAL_URL,
        useSynthetic: import.meta.env.VITE_USE_SYNTHETIC === 'true',
        helpUrl: {
            'nl-NL': import.meta.env.VITE_HELP_URL_NL,
            'en-US': import.meta.env.VITE_HELP_URL_EN,
            'de-DE': import.meta.env.VITE_HELP_URL_DE,
        },
        developmentsUrl: {
            'nl-NL': import.meta.env.VITE_DEVELOPMENTS_URL_NL,
            'en-US': import.meta.env.VITE_DEVELOPMENTS_URL_EN,
            'de-DE': import.meta.env.VITE_DEVELOPMENTS_URL_DE,
        },
        okta: {
            auctionPreparationAppClientId: import.meta.env.VITE_AUCTION_PREPARATION_APP_CLIENT_ID,
            apiClientId: import.meta.env.VITE_API_CLIENT_ID,
            issuer: import.meta.env.VITE_ISSUER,
            redirectUri: import.meta.env.VITE_REDIRECT_URI,
            scopes: ['openid', 'profile', 'role:customer', 'role:app'],
        },
        sentry: {
            enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',
            dsn: import.meta.env.VITE_SENTRY_DSN,
            environmentName: import.meta.env.VITE_SENTRY_ENVIRONMENT_NAME,
        },
        intercom: {
            enabled: import.meta.env.VITE_INTERCOM_ENABLED === 'true',
            customer: import.meta.env.VITE_INTERCOM_CUSTOMER,
        },
    };
}

export default getConfiguration();
