import { Preset } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

import { FloridayAppsPortalClient } from '@api/FloridayAppsPortalClient';
import config from '@config';
import { SELECTED_PRESET_KEY } from '@constants/storageKeys';
import { getAccessToken } from '@features/Auth/auth';
import { loadFromLocalStorage } from '@utils/LocalStorageUtils';
import { ApiClientWrapper } from './ApiClientWrapper';

export default class AppsPortalClient extends ApiClientWrapper<FloridayAppsPortalClient> {
    private static _instance: AppsPortalClient;

    private constructor() {
        super();
    }

    public static get instance(): AppsPortalClient {
        if (!this._instance) {
            this._instance = new AppsPortalClient();
        }
        this._instance.setOrganizationId();
        this._instance.setToken();
        return this._instance;
    }

    public setToken() {
        const client = this.getApiClient();
        getAccessToken().then(token => {
            if (token) {
                client.setToken(token);
            }
        });
    }

    public setOrganizationId(): void {
        const client = this.getApiClient();
        const preset: Preset = loadFromLocalStorage(SELECTED_PRESET_KEY);
        const organizationId = preset?.organizationId ?? '';
        client.setOrganizationId(organizationId);
    }

    protected createServiceClient = (baseUrl: string): FloridayAppsPortalClient =>
        new FloridayAppsPortalClient(baseUrl);

    apiUrl = (): string => config.appsPortalUrl;
}
