import { CloseIcon } from '@floriday/floriday-icons';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { namespaces } from '@root/i18n';
import { useBreakpointContext } from '@store/Providers/BreakpointProvider';
import { useUserSettings } from '@store/Providers/UserSettingsProvider';
import MarkDirectlySetting from './Components/MarkDirectlySetting/MarkDirectlySetting';
import MarkForGroupSetting from './Components/MarkForGroupSetting/MarkForGroupSetting';
import { useStyles } from './SettingsStyles';

interface IProps {
    readonly showDialog: boolean;
    readonly onCancel: () => void;
}

export default function Settings({ showDialog, onCancel }: IProps) {
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.general);
    const { isBreakpointSmall } = useBreakpointContext();
    const { userSettings, updateUserSettings } = useUserSettings();

    function cancel() {
        onCancel();
    }

    function onMarkForGroupChange(isForGroup: boolean) {
        const updatedUserSettings = {
            ...userSettings,
            markForGroup: isForGroup,
        };

        updateUserSettings(updatedUserSettings);
    }

    function onMarkDirectlyChange(checked: boolean) {
        const updatedUserSettings = {
            ...userSettings,
            markDirectlyOnScan: checked,
        };

        updateUserSettings(updatedUserSettings);
    }

    return (
        <Dialog
            fullScreen={isBreakpointSmall}
            open={showDialog}
            onClose={cancel}
            classes={{ paper: classes.orderDialog }}
        >
            <DialogTitle>
                {t('userMenu.settings')}
                <IconButton className={classes.closeButton} onClick={cancel} size='large'>
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.root} data-testid='settingsDialogContent'>
                <MarkForGroupSetting groupSelected={userSettings?.markForGroup} onChange={onMarkForGroupChange} />
                <MarkDirectlySetting onChange={onMarkDirectlyChange} checked={!!userSettings?.markDirectlyOnScan} />
            </DialogContent>
        </Dialog>
    );
}
