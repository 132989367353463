import { useMutation } from '@tanstack/react-query';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { handleApiGetError } from '@api/helper';
import { getUserAccount } from '@features/Auth/auth';
import { ISupplyLineMarkingCommand } from '@features/Supply/Contracts/ISupplyLineMarkingCommand';
import {
    addMarking,
    updateMarking as updateMarkingAction,
    deleteMarking as deleteMarkingAction,
    deleteMarkings as deleteMarkingsAction,
    addMarkings,
} from '@store/Actions/markingsActions';
import { useMarkingsContext } from '@store/Providers/MarkingsProvider';

type ISupplyLineMarking = Omit<ISupplyLineMarkingCommand, 'userName' | 'creationUserName'>;

export function useMarking() {
    const client = AuctionPreparationClient.instance.getApiClient();
    const { dispatch } = useMarkingsContext();

    const createMarking = useMutation(
        async ({
            supplyLineMarkingCommand,
            username,
        }: {
            supplyLineMarkingCommand: ISupplyLineMarking;
            username?: string;
        }) => {
            const account = await getUserAccount();

            if (!username || username === account.username) {
                dispatch(
                    addMarking({
                        ...supplyLineMarkingCommand,
                        userName: account.username,
                        creationUserName: account.username,
                    }),
                );
            }

            return client.createMarkings({
                clockSupplyLineIds: [supplyLineMarkingCommand.clockSupplyLineId],
                userName: username,
                comment: supplyLineMarkingCommand.comment,
                amount: supplyLineMarkingCommand.amount,
                isFromScan: window.location.href.includes('/scan'),
            });
        },
        {
            onError: (error, supplyLineMarkingCommand) => {
                dispatch(deleteMarkingAction(supplyLineMarkingCommand.supplyLineMarkingCommand.clockSupplyLineId));
                handleApiGetError(error);
            },
        },
    );

    const updateMarking = useMutation(
        async ({ currentMarking }: { currentMarking: ISupplyLineMarking; oldMarking: ISupplyLineMarkingCommand }) => {
            const account = await getUserAccount();

            dispatch(
                updateMarkingAction({
                    ...currentMarking,
                    userName: account.username,
                    creationUserName: account.username,
                }),
            );

            return client.updateMarking({
                clockSupplyLineId: currentMarking.clockSupplyLineId,
                comment: currentMarking.comment,
                amount: currentMarking.amount,
                userName: account.username,
            });
        },
        {
            onError: (error, { oldMarking }) => {
                dispatch(updateMarkingAction(oldMarking));
                handleApiGetError(error);
            },
        },
    );

    const deleteMarking = useMutation(
        async ({
            clockSupplyLineId,
            username,
        }: {
            clockSupplyLineId: string;
            supplyLineMarkingCommand?: ISupplyLineMarkingCommand;
            username?: string;
        }) => {
            const account = await getUserAccount();

            if (!username || username === account.username) dispatch(deleteMarkingAction(clockSupplyLineId));

            return client.removeMarkings({
                clockSupplyLineIds: [clockSupplyLineId],
                userName: username,
            });
        },
        {
            onError: (error, supplyLineMarkingCommand) => {
                if (supplyLineMarkingCommand.supplyLineMarkingCommand)
                    dispatch(addMarking(supplyLineMarkingCommand.supplyLineMarkingCommand));
                handleApiGetError(error);
            },
        },
    );

    const createMarkings = useMutation(
        async ({ supplyLines, username }: { supplyLines: string[]; username?: string }) => {
            const account = await getUserAccount();

            if (!username || username === account.username) {
                dispatch(
                    addMarkings(
                        supplyLines.map(id => ({
                            clockSupplyLineId: id,
                            userName: account.username,
                            creationUserName: account.username,
                        })),
                    ),
                );
            }

            return client.createMarkings({
                clockSupplyLineIds: supplyLines ?? [],
                userName: username ?? account.username,
                isFromScan: window.location.href.includes('/scan'),
            });
        },
        {
            onError: (error, { supplyLines }) => {
                dispatch(deleteMarkingsAction(supplyLines));
                handleApiGetError(error);
            },
        },
    );

    const deleteMarkings = useMutation(
        (supplyLines: string[]) => {
            dispatch(deleteMarkingsAction(supplyLines));
            return client.removeMarkings({ clockSupplyLineIds: supplyLines ?? [] });
        },
        {
            onError: async (error, supplyLines) => {
                const account = await getUserAccount();

                dispatch(
                    addMarkings(
                        supplyLines.map(id => ({
                            clockSupplyLineId: id,
                            userName: account.username,
                            creationUserName: account.username,
                        })),
                    ),
                );
                handleApiGetError(error);
            },
        },
    );

    return { createMarkings, createMarking, updateMarking, deleteMarking, deleteMarkings };
}
