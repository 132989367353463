import { createContext, useContext, ReactNode, useMemo } from 'react';

import AppsPortalClient from '@api/AppsPortalClient';
import { handleApiGetError } from '@api/helper';

interface ActivityTrackerContextValue {
    trackActivity: (activity: string) => void;
}

interface IProps {
    readonly children?: ReactNode;
}

const ActivityTrackerContext = createContext<ActivityTrackerContextValue>({
    trackActivity: () => undefined,
});

const client = AppsPortalClient.instance.getApiClient();

export function ActivityTrackerProvider(props: IProps) {
    const { children } = props;

    const value = useMemo<ActivityTrackerContextValue>(
        () => ({
            trackActivity,
        }),
        [],
    );

    function trackActivity(activity: string) {
        client.trackActivityForApp({ event: activity }).catch(error => {
            handleApiGetError(error);
        });
    }

    return <ActivityTrackerContext.Provider value={value}>{children}</ActivityTrackerContext.Provider>;
}

export function useActivityTracker(): ActivityTrackerContextValue {
    return useContext(ActivityTrackerContext);
}
