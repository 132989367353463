import { useEffect, useState, PropsWithChildren } from 'react';
import { LoadingPage } from '@floriday/floriday-ui';
import { JWTObject } from '@okta/okta-auth-js';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';

import { useScanClaimContext } from '@store/Providers/ScanClaimProvider';
import LoginError from './LoginError';
import { oktaAuthClient, hasTokenExpired, getAndDecodeAccessToken, getUserAccount } from '../auth';

export default function AuthContainer(props: Readonly<PropsWithChildren>) {
    const { children } = props;
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
    const [hookIsPresent, setHookIsPresent] = useState<boolean>(false);
    const [paaClaimIsPresent, setPaaClaimIsPresent] = useState<boolean>(false);
    const location = useLocation();
    const { setHasPaaScanClaim } = useScanClaimContext();
    useEffect(() => {
        async function checkAuth() {
            await oktaAuthClient.start();

            const isAuthenticated = await oktaAuthClient.isAuthenticated();

            if (isAuthenticated) {
                const userInfo = await getUserAccount();
                Sentry.setTag('userName', userInfo.username);

                const tokenExpired = await hasTokenExpired();
                if (tokenExpired) {
                    await oktaAuthClient.stop();
                    await oktaAuthClient.signInWithRedirect();
                    return;
                }
                setIsLoggedIn(true);

                const accessToken = (await getAndDecodeAccessToken()) as JWTObject;
                setHookIsPresent(accessToken?.payload?.hookSucceeded != undefined);
                setPaaClaimIsPresent(!!accessToken?.payload?.paaAccessClaim);
                setHasPaaScanClaim(!!accessToken?.payload?.paaScanClaim);
                setIsPageLoaded(true);
            } else {
                oktaAuthClient.setOriginalUri(`${location.pathname}${location.search}`);
                oktaAuthClient.signInWithRedirect();
            }
        }

        checkAuth();
    }, []);
    if (!isPageLoaded) {
        return <LoadingPage />;
    }
    if (!hookIsPresent) {
        return (
            <LoginError
                error={{
                    name: '',
                    message: 'User does not have oktahook.',
                }}
            />
        );
    }
    if (!paaClaimIsPresent) {
        return (
            <LoginError
                error={{
                    name: '',
                    message: 'User does not have paaClaim.',
                }}
            />
        );
    }
    if (isLoggedIn) {
        return <>{children}</>;
    }
    return <LoadingPage />;
}
