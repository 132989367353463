import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { lastAgreementUpdate } from '@constants/userAgreementConstants';
import i18n, { namespaces } from '@root/i18n';
import { useBreakpointContext } from '@store/Providers/BreakpointProvider';
import { useUserAgreement } from '@store/Providers/UserAgreementProvider';
import { useStyles } from './UserAgreementDialogStyles';

export default function UserAgreementDialog() {
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.general);

    const { agreementMoment, isInitialized, updateUserAgreement } = useUserAgreement();
    const { isBreakpointSmall } = useBreakpointContext();
    const isUpToDate = agreementMoment && new Date(agreementMoment) >= new Date(lastAgreementUpdate);

    return (
        <Dialog fullScreen={isBreakpointSmall} open={isInitialized && !isUpToDate} data-testid='userAgreementDialog'>
            <DialogTitle>{t('userAgreement.title')}</DialogTitle>
            <DialogContent className={classes.content}>
                <Trans
                    i18nKey='userAgreement.content'
                    components={[
                        <Link
                            key={'userAgreementLink'}
                            target='_blank'
                            href={`https://intercom.help/royal-floraholland-buyer-helpcenter/${i18n.language.substring(0, 2)}/articles/10091673`}
                            rel='noreferrer'
                            underline='always'
                        />,
                    ]}
                />
            </DialogContent>
            <DialogActions>
                <Button color='primary' size='medium' onClick={updateUserAgreement}>
                    {t('userAgreement.accept')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
