import React from 'react';
import { captureConsoleIntegration } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

import config from '@config';

export function initializeSentry() {
    if (config.sentry.enabled) {
        Sentry.init({
            dsn: config.sentry.dsn,
            normalizeDepth: 10,
            tracePropagationTargets: [config.apiUrl],
            integrations: [
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect: React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                    beforeStartSpan: context => ({
                        ...context,
                        name: location.pathname.replace(/\/[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}/g, '/:id'), // replace GUID with generic :id to group parameterized transactions
                    }),
                }),
                captureConsoleIntegration({ levels: ['error'] }),
            ],
            autoSessionTracking: true,
            attachStacktrace: true,
            release: process.env.VERSION ?? 'unknown',
            environment: config.sentry.environmentName,
            tracesSampleRate: 1.0,
            initialScope: scope => {
                scope.setTags({ application: 'auction-preparation-app', team: 'digital-clock-supply' });
                scope.addEventProcessor(event => {
                    if (event.type === 'transaction' && event.transaction === '/*') {
                        event.transaction = location.pathname.replace(
                            /\/[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}/g,
                            '/:id',
                        );
                    }

                    return event;
                });

                return scope;
            },
        });
    }
}
