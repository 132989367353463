import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    buyButton: {
        width: '40px',
        height: '40px',
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[50],
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        '&:first-of-type': {
            marginTop: theme.spacing(1.5),
        },
        '&:last-child': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
    },
    rowStart: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 900,
        gap: theme.spacing(1),
    },
    rowMiddle: {
        alignItems: 'center',
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        overflow: 'hidden',
    },
    rowEnd: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        gap: theme.spacing(1),
    },
    productName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    unavailableText: {
        fontStyle: 'italic',
    },
    quantityPrice: {
        textAlign: 'right',
        color: theme.palette.primary.main,
    },
}));
