//----------------------
// Created manual based on needed api calls in https://api.staging.floriday.io/apps-portal/api-spec
//----------------------

/* tslint:disable */
/* eslint-disable */
// ReSharper disable InconsistentNaming

export class CustomHeader {
    organizationId = "";
    token = "";
    setOrganizationId(organizationId: string) {
        this.organizationId = organizationId;
    }

    setToken(token: string) {
        this.token = token;
    }

    protected async transformOptions(options: RequestInit): Promise<RequestInit> {
        options.headers = {
            ...options.headers,
            "X-Selected-Organization-Id": this.organizationId,
            Authorization: 'Bearer ' + this.token,
        };
        return options;
    }
}

export class FloridayAppsPortalClient extends CustomHeader {
    private readonly http: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> };
    private readonly baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) {
        super();
        this.http = http ?? window as any;
        this.baseUrl = baseUrl ?? "";
    }

    /**
     * TrackActivityForApp
     * @param activity
     */
    async trackActivityForApp(activity: Activity): Promise<void> {
        let url_ = this.baseUrl + "/activity-tracker/activity";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(activity);

        let options_: RequestInit = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            }
        };

        const transformedOptions_ = await this.transformOptions(options_);
        const _response = await this.http.fetch(url_, transformedOptions_);
        return await this.processSaveUserSetting(_response);
    }

    protected async processSaveUserSetting(response: Response): Promise<void> {
        const status = response.status;
        let _headers: any = {}; if (response.headers?.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); }
        if (status === 401) {
            let _responseText = await response.text();
            let result401: any;
            result401 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as ProblemDetails;
            return throwException("A server side error occurred.", status, _responseText, _headers, result401);
        } else if (status === 403) {
            let _responseText1 = await response.text();
            let result403: any;
            result403 = _responseText1 === "" ? null : JSON.parse(_responseText1, this.jsonParseReviver) as ProblemDetails;
            return throwException("A server side error occurred.", status, _responseText1, _headers, result403);
        } else if (status === 200) {
            await response.text();
            return;
        } else if (status === 400) {
            let _responseText3 = await response.text();
            let result400: any;
            result400 = _responseText3 === "" ? null : JSON.parse(_responseText3, this.jsonParseReviver) as ErrorResponse;
            return throwException("A server side error occurred.", status, _responseText3, _headers, result400);
        } else if (status === 500) {
            let _responseText4 = await response.text();
            return throwException("A server side error occurred.", status, _responseText4, _headers);
        } else if (status !== 200 && status !== 204) {
            let _responseText5 = await response.text();
            return throwException("An unexpected server error occurred.", status, _responseText5, _headers);
        }
        return Promise.resolve<void>(null as any);
    }
}

export interface Activity {
    event: string | undefined;
}

export interface ProblemDetails {
    type?: string;
    title?: string;
    status?: number;
    detail?: string;
    instance?: string;

    [key: string]: any;
}

export interface ErrorResponse {
    errorMessages: string[];
    requestId?: string ;
}

export class SwaggerException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isSwaggerException = true;

    static isSwaggerException(obj: any): obj is SwaggerException {
        return obj.isSwaggerException === true;
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    throw new SwaggerException(message, status, response, headers, result);
}