import { Suspense } from 'react';
import { LoadingPage } from '@floriday/floriday-ui';
import { Outlet } from 'react-router';

import UserAgreementDialog from '../UserAgreementDialog/UserAgreementDialog';

export default function SuspenseRoute() {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Outlet />
            <UserAgreementDialog />
        </Suspense>
    );
}
