import { useState } from 'react';
import { PopoverTable, ToggleIconButton } from '@floriday/floriday-ui';
import { Badge, Box, IconButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useHoverDebounce } from '@root/Hooks/useHoverDebounce';
import { namespaces } from '@root/i18n';
import FlagGroupIcon from '@resources/Icons/FlagGroupIcon';
import FlagGroupOutlineIcon from '@resources/Icons/FlagGroupOutlineIcon';
import FlagIcon from '@resources/Icons/FlagIcon';
import FlagOutlineIcon from '@resources/Icons/FlagOutlineIcon';
import { useStyles } from './MarkButtonStyles';

interface MarkButtonProps extends IconButtonProps {
    readonly marked?: boolean;
    readonly comment?: string;
    readonly quantity?: number;
    readonly markForGroup?: boolean;
}

export default function MarkButton(props: MarkButtonProps) {
    const { marked, comment, quantity, disabled, markForGroup, ...buttonProps } = props;
    const { t } = useTranslation(namespaces.supply, { keyPrefix: 'markButton' });
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const hovered = useHoverDebounce(anchorEl);
    const popoverItems = [];

    if (quantity) popoverItems.push({ label: t('quantity'), value: String(quantity) });
    if (comment) popoverItems.push({ label: t('comment'), value: comment });

    const { classes } = useStyles();

    function handlePopoverOpen(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        setAnchorEl(event.currentTarget);
    }

    function handlePopoverClose() {
        setAnchorEl(undefined);
    }

    return (
        <Box
            className={classes.marked}
            display='flex'
            alignItems='center'
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            <ToggleIconButton
                aria-label='mark'
                className={classes.button}
                icon={
                    markForGroup ? (
                        <FlagGroupOutlineIcon data-testid='markingIcon' className={classes.icon} />
                    ) : (
                        <FlagOutlineIcon data-testid='markingIcon' className={classes.icon} />
                    )
                }
                selectedIcon={
                    <Badge
                        badgeContent={marked && popoverItems.length > 0 ? 'i' : null}
                        className={classes.badge}
                        data-testid='markedBadge'
                    >
                        {markForGroup ? (
                            <FlagGroupIcon className={disabled && marked ? classes.markedDisabled : classes.icon} />
                        ) : (
                            <FlagIcon className={disabled && marked ? classes.markedDisabled : classes.icon} />
                        )}
                    </Badge>
                }
                isSelected={marked ?? false}
                disabled={disabled}
                {...buttonProps}
            />
            {marked && (
                <PopoverTable
                    data-testid='markingPopover'
                    open={!!anchorEl && hovered && popoverItems.length > 0}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    items={popoverItems}
                    title={t('popOverTitle')}
                    slotProps={{ paper: { className: classes.popover } }}
                />
            )}
        </Box>
    );
}
