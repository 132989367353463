import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    orderDialog: {
        [theme.breakpoints.down('sm')]: {
            maxHeight: '100%',
            width: '100%',
            maxWidth: '100%',
        },
        width: '50%',
        maxWidth: '440px',
    },
    closeButton: {
        float: 'right',
    },
    closeIcon: {
        color: theme.palette.grey[900],
        fontSize: '14px',
    },
}));
