import { UserGroup } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { UseQueryOptions, useQuery, QueryKey } from '@tanstack/react-query';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';
import { CACHE_TIME, STALE_TIME } from '@constants/cacheTimeConstants';

export function useUserGroup(options?: UseQueryOptions<UserGroup | null>) {
    const client = AuctionPreparationClient.instance.getApiClient();

    function queryFn() {
        return client.getUserGroup();
    }

    return useQuery(['userGroup'] as QueryKey, () => authenticatedRequest(queryFn), {
        ...options,
        onError: error => {
            handleApiGetError(error);
        },
        cacheTime: CACHE_TIME,
        staleTime: STALE_TIME,
    });
}
