import { useEffect, useState, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IntercomProps, IntercomProvider as ReactIntercomProvider } from 'react-use-intercom';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';
import config from '@config';
import { getUserAccount } from '@features/Auth/auth';
import i18n from '@root/i18n';
import { usePresetContext } from './PresetsProvider';

interface IIntercomContextValue {
    unreadMessageCount: number;
    intercomProps?: IntercomProps;
}

const IntercomContext = createContext<IIntercomContextValue>({
    unreadMessageCount: 0,
    intercomProps: {},
});

export default function IntercomProvider(props: Readonly<PropsWithChildren>) {
    const { children } = props;
    const key = config.intercom.customer ?? '';
    const { selectedPreset } = usePresetContext();
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [userHash, setUserHash] = useState<string>();
    const [intercomProps, setIntercomProps] = useState<IntercomProps>();
    const client = AuctionPreparationClient.instance.getApiClient();

    const value = useMemo<IIntercomContextValue>(
        () => ({
            unreadMessageCount,
            intercomProps,
        }),
        [unreadMessageCount, intercomProps],
    );

    function getIntercomIdentityVerificationHash() {
        return client.getIntercomIdentityVerificationHash('customer');
    }

    function onUnreadCountChange(amount: number) {
        setUnreadMessageCount(amount);
    }

    useQuery(['userHash'], () => authenticatedRequest(getIntercomIdentityVerificationHash), {
        onSuccess: data => {
            if (data) {
                setUserHash(data);
            }
        },
        onError: error => {
            handleApiGetError(error);
        },
        enabled: config.intercom.enabled,
    });

    useEffect(() => {
        async function initIntercom() {
            const userAccount = await getUserAccount();
            const organizationId = selectedPreset?.organizationId;
            const organizationName = selectedPreset?.organizationName;

            const relationNumber = selectedPreset?.organizationRelationNumber;
            if (userHash) {
                setIntercomProps({
                    hideDefaultLauncher: true,
                    alignment: 'right',
                    languageOverride: i18n.language,
                    email: userAccount.username,
                    userId: userAccount.id,
                    name: userAccount.name,
                    userHash,
                    company: {
                        companyId: organizationId ?? '', // 28-10-2022; Should be GLN but we don't have this information... yet
                        name: organizationName,
                        customAttributes: {
                            company_administration_number: relationNumber,
                        },
                    },
                    customAttributes: {
                        organizationId,
                    },
                });
            }
        }

        if (config.intercom.enabled) {
            initIntercom();
        }
    }, [userHash]);

    return (
        <IntercomContext.Provider value={value}>
            <ReactIntercomProvider
                appId={key}
                autoBoot
                shouldInitialize={!!intercomProps}
                autoBootProps={intercomProps}
                onUnreadCountChange={onUnreadCountChange}
            >
                {children}
            </ReactIntercomProvider>
        </IntercomContext.Provider>
    );
}

export function useIntercomContext(): IIntercomContextValue {
    return useContext(IntercomContext);
}
