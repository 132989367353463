import { Box, Chip, DialogContentText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { namespaces } from '@root/i18n';
import { useStyles } from './MarkForGroupSettingStyles';

interface IProps {
    readonly onChange: (selection: boolean) => void;
    readonly groupSelected?: boolean;
}

export default function MarkForGroupSetting({ groupSelected, onChange }: IProps) {
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.general);

    return (
        <>
            <Typography variant='h4'>{t('settings.markForGroupTitle')}</Typography>
            <Box className={classes.container}>
                <Chip
                    label={t('settings.ownAccount')}
                    color={!groupSelected ? 'primary' : 'default'}
                    onClick={() => onChange(false)}
                    disabled={!groupSelected}
                    aria-pressed={!groupSelected}
                />
                <DialogContentText>{t('settings.or')}</DialogContentText>
                <Chip
                    label={t('settings.myGroup')}
                    color={groupSelected ? 'primary' : 'default'}
                    onClick={() => onChange(true)}
                    disabled={groupSelected}
                    aria-pressed={!!groupSelected}
                />
            </Box>
        </>
    );
}
