type AddMarkingUsers = {
    type: 'ADD_MARKING_USERS';
    clockSupplyLineId: string;
    users: string[];
};

type AddMarkingUser = {
    type: 'ADD_MARKING_USER';
    clockSupplyLineId: string;
    user: string;
};

type AddMarkingUsersForSupplyLines = {
    type: 'ADD_MARKING_USERS_FOR_SUPPLY_LINES';
    clockSupplyLineIds: string[];
    users: string[];
};

type DeleteMarkingUser = {
    type: 'DELETE_MARKING_USER';
    clockSupplyLineId: string;
    user: string;
};

type DeleteMarkingUsers = {
    type: 'DELETE_MARKING_USERS';
    clockSupplyLineId: string;
    users: string[];
};

export type MarkingUsersActions =
    | AddMarkingUsers
    | AddMarkingUser
    | AddMarkingUsersForSupplyLines
    | DeleteMarkingUser
    | DeleteMarkingUsers;

export function addMarkingUsers(clockSupplyLineId: string, users: string[]): AddMarkingUsers {
    return {
        type: 'ADD_MARKING_USERS',
        clockSupplyLineId,
        users,
    };
}

export function addMarkingUser(clockSupplyLineId: string, user: string): AddMarkingUser {
    return {
        type: 'ADD_MARKING_USER',
        clockSupplyLineId,
        user,
    };
}

export function addMarkingUsersForSupplyLines(
    clockSupplyLineIds: string[],
    users: string[],
): AddMarkingUsersForSupplyLines {
    return {
        type: 'ADD_MARKING_USERS_FOR_SUPPLY_LINES',
        clockSupplyLineIds,
        users,
    };
}

export function deleteMarkingUser(clockSupplyLineId: string, user: string): DeleteMarkingUser {
    return {
        type: 'DELETE_MARKING_USER',
        clockSupplyLineId,
        user,
    };
}

export function deleteMarkingUsers(clockSupplyLineId: string, users: string[]): DeleteMarkingUsers {
    return {
        type: 'DELETE_MARKING_USERS',
        clockSupplyLineId,
        users,
    };
}
