import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    switch: {
        '&.MuiFormControlLabel-root, .MuiFormControlLabel-label': {
            margin: 0,
        },
    },
}));
