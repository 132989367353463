import { Box, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { namespaces } from '@root/i18n';
import { useStyles } from './MarkDirectlySettingStyles';

interface IProps {
    readonly checked: boolean;
    readonly onChange: (checked: boolean) => void;
}

export default function MarkDirectlySetting({ checked, onChange }: IProps) {
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.general);

    return (
        <Box className={classes.container}>
            <Typography variant='h4'>{t('settings.directMarkingTitle')}</Typography>
            <FormGroup>
                <FormControlLabel
                    className={classes.switch}
                    control={
                        <Switch
                            checked={checked}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={t('settings.description')}
                    labelPlacement='start'
                />
            </FormGroup>
        </Box>
    );
}
