import { useEffect, useState } from 'react';

import { MARK_DEBOUNCE_TIME } from '@constants/debounceConstants';
import MarkButton from './Components/MarkButton/MarkButton';

interface IProps {
    readonly selected: boolean;
    readonly quantity?: number;
    readonly comment?: string;
    readonly onChange: (selected: boolean) => void;
    readonly markForGroup?: boolean;
}

export default function MarkButtonContainer(props: IProps) {
    const { selected, quantity, comment, onChange, markForGroup } = props;
    const [isMarked, setIsMarked] = useState(selected);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    function toggleSelected() {
        setIsMarked(!isMarked);
        onChange(!isMarked);
        setIsButtonDisabled(true);
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, MARK_DEBOUNCE_TIME);
    }

    useEffect(() => {
        setIsMarked(selected);
    }, [selected]);

    return (
        <MarkButton
            disabled={isButtonDisabled}
            marked={isMarked}
            comment={comment}
            quantity={quantity}
            onClick={_event => {
                _event.stopPropagation();
                toggleSelected();
            }}
            markForGroup={markForGroup}
        />
    );
}
